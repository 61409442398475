import React from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';

const XLSX = require('xlsx');

const insuranceCompanyNameCheck = (value) => {
    if (value.insuranceProduct === undefined) {
        if(value.PaymentTypeId !== 8){
            return "Ödeme";
        }
        else{
            return "-";
        } 
    }
    else
        return value.insuranceProduct.insuranceCompany.name;
};

const formatDate = (value) => {
    if (value !== "") {
        return new Date(value).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
}

const formatCurrency = (value, currencyType = "TRY") => {
    if (value === undefined) {
        return 0;
    }

    return value.toLocaleString('en-US', { style: 'currency', currency: currencyType }).replace(',', '.');
};
const formatCurrencyWithoutType = (value) => {
    if (value === undefined) {
        return 0;
    }

    return value.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};


const ExcelExport = ({ data2, data, data3, data4, dollarRate, euroRate }) => {
    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Verilerin toplamlarını hesapla
        const totalGrossPremium = data.reduce((sum, item) => sum + (data4 ? item.grossPremium * item.currencyRate : item.grossPremium), 0);
        const totalServiceFee = data.reduce((sum, item) => sum + (data4 ? item.serviceFee * item.currencyRate : item.serviceFee), 0);
        const totalMovementAmount = data.reduce((sum, item) => sum + (data4 ? item.movementAmount * item.currencyRate : item.movementAmount), 0);
        const totalBalanceTl = data.reduce((sum, item) => sum + (data4 ? item.balanceTl * item.currencyRate : item.balanceTl), 0);
        const totalBalanceUsd = data.reduce((sum, item) => sum + (data4 ? item.balanceUsd * item.currencyRate : item.balanceUsd), 0);
        const totalBalanceEur = data.reduce((sum, item) => sum + (data4 ? item.balanceEur * item.currencyRate : item.balanceEur), 0);

        // Worksheet oluştur ve başlıkları ekle
        const worksheet = XLSX.utils.aoa_to_sheet([
            ['-', 'Devir', 'Prim', 'Hiz. Bed.', 'Borç', 'Alacak', 'Bakiye'], // Başlıklar satırı
            ["Toplam TL",
                data4 ?'-':data2.totalPagePeriodTl,
                data2.totalPageGrossPremiumTl,
                data2.totalPageServiceFeeTl,
                data2.totalPageDebtTl,
                data2.totalPageCreditorTl,
                data2.totalPageBalanceTl
            ],
            ["Toplam EUR",
                data4 ?'-':data2.totalPagePeriodEur,
                data2.totalPageGrossPremiumEur,
                data2.totalPageServiceFeeEur,
                data2.totalPageDebtEur,
                data2.totalPageCreditorEur,
                data2.totalPageBalanceEur
            ],
            ["Toplam USD",
                data4 ?'-':data2.totalPagePeriodUsd,
                data2.totalPageGrossPremiumUsd,
                data2.totalPageServiceFeeUsd,
                data2.totalPageDebtUsd,
                data2.totalPageCreditorUsd,
                data2.totalPageBalanceUsd
            ],
            ["Genel Toplam TL",
                data4 ? '-': data2.totalPagePeriodEntire,
                data4 ? data2.totalPageGrossPremiumEntireEquivalentRate : data2.totalPageGrossPremiumEntire,
                data4 ? data2.totalPageServiceFeeEntireEquivalentRate : data2.totalPageServiceFeeEntire,
                data4 ? data2.totalPageDebtEntireEquivalentRate : data2.totalPageDebtEntire,
                data4 ? data2.totalPageCreditorEntireEquivalentRate : data2.totalPageCreditorEntire,
                data4 ? data2.totalPageBalanceEntireEquivalentRate : data2.totalPageBalanceEntire
            ],
            ['', ''],
            [data3.subAccountName],
            [formatDate(data3.startDate), formatDate(data3.endDate)],
            ['', ''],
            ['Şirket', 'Tanzim T.', 'Poliçe No', 'Zeyil', 'Açıklama', 'Bas. Tar.', 'Bit. Tar.', 'Sigortalı', 'Kur', 'Cinsi', 'Prim', 'Hiz.Bedl.', 'Borç/Alacak', 'Bakiye TL', 'Bakiye Usd', 'Bakiye Eur'],
        ]);

        // Veri satırlarını ekleyelim
        XLSX.utils.sheet_add_aoa(worksheet, [
            ...data.map((item) => [
                insuranceCompanyNameCheck(item),
                formatDate(item.issueDate),
                item.policyNumber,
                item.addendumNo,
                item.description,
                formatDate(item.policyStartDate),
                formatDate(item.policyEndDate),
                item.insuredPersonName,
                item.currencyRate,
                item.currencyType.name,
                data4 ? item.grossPremium * item.currencyRate : item.grossPremium,
                data4 ? item.serviceFee * item.currencyRate : item.serviceFee,
                data4 ? item.movementAmount * item.currencyRate : item.movementAmount,
                data4 ? item.balanceTl * item.currencyRate : item.balanceTl,
                data4 ? item.balanceUsd * item.currencyRate : item.balanceUsd,
                data4 ? item.balanceEur * item.currencyRate : item.balanceEur,
            ]),
        ], { origin: -1 });

        // Toplam satırını ekleyelim
        XLSX.utils.sheet_add_aoa(worksheet, [
            ['Toplam', '', '', '', '', '', '', '', '', '', totalGrossPremium, totalServiceFee, totalMovementAmount, '', '','']
        ], { origin: -1 });

        // Boşluk ve döviz kuru açıklaması ekleyelim
        XLSX.utils.sheet_add_aoa(worksheet, [
            ['', ''],
            ['Ödeme tarihindeki T.C. Merkez Bankası döviz satış kuru esas alınır.'],
            [`${formatDate(new Date())} Dolar: ${dollarRate} TL | Euro: ${euroRate} TL`],
            ['', ''],
            ['M. NO:', '23741937 - TS NETHAYAT SİGORTA ARACILIK HİZMETLERİ LTD.ŞTİ.'],
            ['BANKA:', 'GARANTİ BANKASI'],
            ['ŞUBE:', 'CUMHURİYET CADDESİ'],
            ['IBAN (TL):', 'TR44 0006 2000 7720 0006 2987 41'],
            ['IBAN (Eur):', 'TR71 0006 2000 7720 0009 0787 60'],
            ['IBAN (Usd):', 'TR44 0006 2000 7720 0009 0787 61'],
            ['BEYOĞLU VD:', '859 057 0769'],
        ], { origin: -1 });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Company Data');
        XLSX.writeFile(workbook, `${data3.subAccountName}.xlsx`);
    };

    return (
        <div className="my-2">
            <Button label={('Excel Hazır')} icon="pi pi-print" className="p-button-success mr-2" onClick={exportToExcel} />
        </div>
    );
};

export default ExcelExport;
