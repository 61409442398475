import React, { useState, useEffect, useRef, useContext } from 'react';

import { deptsModel } from './models/depts.model';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
//import { FileUpload } from 'primereact/fileupload';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { JwtHelper } from '../../plugins/jwtHelper';
import { Font, PDFDownloadLink } from '@react-pdf/renderer';
import { Panel } from 'primereact/panel';

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

import { addDept, getDeptList, updateDept, getDeptById, deleteDept, filterDepts, refundDeptsToPool, updateElementersStatus } from '../depts/services/depts.service';
// import { getPoolList, updatePool, deletePool, getPoolById } from '../pools/services/pools.service'; 
import { getSubAccountById, getSubAccountList, getSubAccountListByMainAccountId } from '../subAccount/services/subAccount.service';
import { getInsuranceProductList } from '../insuranceProduct/services/insuranceProduct.service';
import { getCurrencyTypesList } from '../currencyTypes/services/currencyTypes.service';
import { getCurrenciesByDate } from '../currencies/services/currencies.service';
import { getProductProportionsListBySubAccountId } from '../productProportions/services/productProportions.service';
import { getMainAccountList } from '../mainAccount/services/mainAccount.service';
import { getPaymentTypesList } from '../paymentTypes/services/paymentTypes.service';
import { getMovementTypesList } from '../movementTypes/services/movementTypes.service';
import { filterModel } from '../poolLogs/models/filterModel';
import DeptsToPdf from './export/deptsPdf.component';
import ExcelExport from './export/deptsExcel.component';
import { Paginator } from 'primereact/paginator';
import { deptsTotalModel } from './models/deptsTotal.model';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import { SplitButton } from 'primereact/splitbutton';
import ProgressBarDiv from '../../plugins/progressBar';

// import ExchangeRateContext from '../../service/ExchangeRateContext.js'; 

const Depts = () => {

    // const exchangeRateData = useContext(ExchangeRateContext);

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [depts, setDepts] = useState([]);
    const [deptsExport, setDeptsExport] = useState([]);
    const [dept, setDept] = useState(deptsModel);
    const [filter, setFilter] = useState(filterModel);
    const [deptsTotal, setDeptsTotal] = useState(deptsTotalModel);
    const [submitted, setSubmitted] = useState(false);
    const [subAccounts, setSubAccounts] = useState([]);
    const [subAccountsforFilter, setSubAccountsforFilter] = useState([]);
    const [deptSaveDialog, setDeptSaveDialog] = useState(false);
    const [deptDeleteDialog, setDeptDeleteDialog] = useState(false);
    const [sendLogsDialog, setSendLogsDialog] = useState(false);
    const [sendLogsDialogElementer, setSendLogsDialogElementer] = useState(false);
    const [mainAccounts, setMainAccounts] = useState([]);
    const [filterCreditCardChecked, setFilterCreditCardChecked] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [pdfGuncelKurDahil, setPdfGuncelKurDahil] = useState(false);

    const [insuranceProducts, setInsuranceProducts] = useState([]);
    const [currencyTypes, setCurrencyTypes] = useState([]);
    const [movementType, setMovementType] = useState([]);
    const [deptCurrencyTypes, setDeptCurrencyTypes] = useState([]);
    // const [currencyRate, setCurrencyRate] = useState(null);
    const [deptCurrencyDate, setDeptCurrencyDate] = useState(null);
    const [currencyDate, setCurrencyDate] = useState(null);
    const [selectedLogs, setSelectedLogs] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState([]);

    const [paymentTables, setPaymentTables] = useState([]);
    const [paymentTable, setPaymentTable] = useState(deptsModel);

    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);


    const [currencyUSD, setCurrencyUSD] = useState([]);
    const [currencyEUR, setCurrencyEUR] = useState([]);
    // const [date, setDate] = useState(new Date());

    const [pdfReady, setPdfReady] = useState(false); // PDF hazır mı kontrol etmek için


    const [customerTypeTcShow, setCustomerTypeTcShow] = useState('hidden field col-2');
    const [customerTypeTaxShow, setCustomerTypeTaxShow] = useState('hidden field col-2');

    const [productProportions, setProductProportions] = useState([]);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    //--------Multiselect
    const [selectSubAccountsValue, setSelectSubAccountsValue] = useState(null);
    const [selectSubAccountsFilterValue, setSelectSubAccountsFilterValue] = useState(null);
    const [selectInsuranceProductsValue, setSelectInsuranceProductsValue] = useState(null);
    const [selectCurrencyTypesValue, setSelectCurrencyTypesValue] = useState(null);
    const [selectDeptCurrencyTypesValue, setSelectDeptCurrencyTypesValue] = useState(null);
    const [selectedMovementType, setSelectedMovementType] = useState(null);
    const [selectMainAccountsValue, setSelectMainAccountsValue] = useState(null);
    const [selectPaymentTypesValue, setSelectPaymentTypesValue] = useState(null);

    // const [selectYesNoValue, setSelectYesNoValue] = useState(null);
    // const selectYesNoValues = [
    //     { name: 'Evet', code: true },
    //     { name: 'Hayır', code: false }
    // ];

    //----------form data Start
    const [isValidateSelectedMovementType, setisValidateSelectedMovementType] = useState(true);
    const [isValidateSelectMainAccountsValue, setisValidateSelectMainAccountsValue] = useState(true);
    const [isValidateSelectSubAccountsValue, setisValidateSelectSubAccountsValue] = useState(true);
    const [isValidateDeptCurrencyDate, setisValidateDeptCurrencyDate] = useState(true);
    const [isValidateSelectDeptCurrencyTypesValue, setisValidateSelectDeptCurrencyTypesValue] = useState(true);
    const [isValidatedDeptCurrencyRate, setisValidatedDeptCurrencyRate] = useState(true);
    const [isValidatedIncomingAmount, setisValidatedIncomingAmount] = useState(true);
    const [isValidateCurrencyDate, setisValidateCurrencyDate] = useState(true);
    const [isValidateSelectCurrencyTypesValue, setisValidateSelectCurrencyTypesValue] = useState(true);
    const [isValidatedCurrencyRate, setisValidatedCurrencyRate] = useState(true);
    const [isValidateSelectPaymentTypesValue, setisValidateSelectPaymentTypesValue] = useState(true);
    const [isValidatedDescription, setisValidatedDescription] = useState(true);
    const [isValidatedMovementAmount, setisValidatedMovementAmount] = useState(true);

    const [paymentRowDeleteDialog, setPaymentRowDeleteDialog] = useState(false);

    const [isInputDisabled, setIsInputDisabled] = useState(true);
    const [printButton, setPrintButton] = useState(true)

    const [date, setDate] = useState(null);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [totalRecords, setTotalRecords] = useState(50);
    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);



    addLocale('tr', {
        firstDayOfWeek: 1,
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cuma'],
        dayNamesShort: ['Pzr', 'Pzrts', 'Sal', 'Çar', 'Per', 'Cuma', 'Cumrts'],
        dayNamesMin: ['P', 'Pt', 'S', 'Ç', 'P', 'C', 'Ct'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        monthNamesShort: ['Ock', 'Şub', 'Mart', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Ek', 'Kas', 'Arl'],
        today: 'Bugün',
        clear: 'Temizle'
    });





    //Get---------
    useEffect(() => {

        // getPoolList()
        // .then(data => setDepts(data))
        // .catch(err => {
        //     toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('PoolListGettingError') + ': ' + err.response.data}`, life: 5000 });
        // })

        // getDeptList()
        //     .then(data => setDepts(data))
        //     .catch(err => {
        //         toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptListGettingError') + ': ' + err.response.data}`, life: 5000 });
        //     })

        getSubAccountList()
            .then(data => {
                setSubAccounts(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));

                // data[0] = { name: "Seçiniz", id: 0 };

                setSubAccountsforFilter(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));


            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountsListGettingError') + ': ' + err.data}`, life: 5000 });
            })

        getInsuranceProductList()
            .then(data => {
                setInsuranceProducts(data.map(function (item, i) {
                    // console.log(item.name + " - " + item.id)
                    return { name: item.insuranceCompany.name + " - " + item.name, code: parseInt(item.id), productsCode: item.productsCode };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('insuranceProductsListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getCurrencyTypesList()
            .then(data => {
                setCurrencyTypes(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
                setDeptCurrencyTypes(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CurrencyTypesListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getMainAccountList()
            .then(data => {
                setMainAccounts(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getPaymentTypesList()
            .then(data => {
                setPaymentTypes(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('PaymentTypesGettingError') + ': ' + err.response.data}`, life: 5000 });
            })

        getMovementTypesList()
            .then(data => {
                setMovementType(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('MainAccountListGettingError') + ': ' + err.response.data}`, life: 5000 });
            })
        setSubmitted(a => !a);


        //currency 
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let today = year + "-" + month + "-" + date;
        let todayText = date + "/" + month + "/" + year;

        setDate(today);

        getCurrenciesByDate(today, "2")
            .then(data => {
                setCurrencyUSD(data.rate);
            })
            .catch(err => {
                setCurrencyUSD("-")
            })

        getCurrenciesByDate(today, "3")
            .then(data => {
                setCurrencyEUR(data.rate);
            })
            .catch(err => {
                setCurrencyEUR("-")
            })

        setDeptCurrencyDate(todayText);

    }, [t]);



    //-------Validation(Check)
    const validForm = (unValidOps) => {

        let tempNoti = [];
        try {

            //movementTypeId
            if (selectedMovementType === null || selectedMovementType.code === "" || selectedMovementType.code === 0 || selectedMovementType.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Giriş Tipi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateSelectedMovementType(false);

            } else {
                setisValidateSelectedMovementType(true);
            }

            //mainAccountId
            if (selectMainAccountsValue === null || selectMainAccountsValue.code === "" || selectMainAccountsValue.code === 0 || selectMainAccountsValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Ana Hesap'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateSelectMainAccountsValue(false);

            } else {
                setisValidateSelectMainAccountsValue(true);
            }

            //SubAccount
            if (selectSubAccountsValue === null || selectSubAccountsValue.code === "" || selectSubAccountsValue.code === 0 || selectSubAccountsValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Kart Seçimi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateSelectSubAccountsValue(false);

            } else {
                setisValidateSelectSubAccountsValue(true);
            }

            //deptCurrencyDate
            if (deptCurrencyDate === null || deptCurrencyDate === "" || deptCurrencyDate === 0 || deptCurrencyDate === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Gelen Döviz Tarihi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateDeptCurrencyDate(false);

            } else {
                setisValidateDeptCurrencyDate(true);
            }

            //deptCurrencyTypeId
            if (selectDeptCurrencyTypesValue === null || selectDeptCurrencyTypesValue.code === "" || selectDeptCurrencyTypesValue.code === 0 || selectDeptCurrencyTypesValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Gelen Döviz'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateSelectDeptCurrencyTypesValue(false);

            } else {
                setisValidateSelectDeptCurrencyTypesValue(true);
            }
            //deptCurrencyRate
            if (dept.deptCurrencyRate === "" || dept.deptCurrencyRate === 0 || dept.deptCurrencyRate === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Gelen Kur'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedDeptCurrencyRate(false);

            } else {
                setisValidatedDeptCurrencyRate(true);
            }

            //incomingAmount
            if (dept.incomingAmount === "" || dept.incomingAmount === 0 || dept.incomingAmount === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Gelen Tutar'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedIncomingAmount(false);

            } else {
                setisValidatedIncomingAmount(true);
            }

            //currencyDate
            if (currencyDate === null || currencyDate === "" || currencyDate === 0 || currencyDate === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Hesap Döviz Tarihi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateCurrencyDate(false);

            } else {
                setisValidateCurrencyDate(true);
            }

            //currencyTypeId
            if (selectCurrencyTypesValue === null || selectCurrencyTypesValue.code === "" || selectCurrencyTypesValue.code === 0 || selectCurrencyTypesValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Hesap Döviz'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateSelectCurrencyTypesValue(false);

            } else {
                setisValidateSelectCurrencyTypesValue(true);
            }

            //currencyRate
            if (dept.currencyRate === "" || dept.currencyRate === 0 || dept.currencyRate === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Hesap Kur'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedCurrencyRate(false);

            } else {
                setisValidatedCurrencyRate(true);
            }

            //paymentTypeId
            if (selectPaymentTypesValue === null || selectPaymentTypesValue.code === "" || selectPaymentTypesValue.code === 0 || selectPaymentTypesValue.code === undefined) {

                tempNoti.push({ severity: 'error', summary: t('Ödeme Türü'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidateSelectPaymentTypesValue(false);

            } else {
                setisValidateSelectPaymentTypesValue(true);
            }

            if (unValidOps !== "onizlemeBtn") {
                //description
                if (dept.description === "" || dept.description === 0 || dept.description === undefined) {

                    tempNoti.push({ severity: 'error', summary: t('Açıklama'), detail: t('Required'), life: 4000, toastId: 'code"' });

                    setisValidatedDescription(false);

                } else {
                    setisValidatedDescription(true);
                }

                //movementAmount
                if (dept.movementAmount === "" || dept.movementAmount === 0 || dept.movementAmount === undefined) {

                    tempNoti.push({ severity: 'error', summary: t('İşlenecek Tutar'), detail: t('Required'), life: 4000, toastId: 'code"' });

                    setisValidatedMovementAmount(false);

                } else {
                    setisValidatedMovementAmount(true);
                }
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }



        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }


    //Save--------
    const save = () => {
        debugger;
        try {

            let validStatus = validForm();
            setIsSubmitted(validStatus);
            if (validStatus) {

                // if (dept.id) {
                //     updateDeptData();
                // }
                // else {
                addDeptData();
                // }

                setDeptSaveDialog(false);
                setDept(deptsModel);
                deptForm_Clear();
            }
        }
        catch (error) {
            setDept(deptsModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }

    //Update----
    const updateDeptData = () => {
        try {

            let _depts = [...depts];
            let _dept = { ...dept };

            updateDept(dept)
                .then(data => {

                    _depts = addToListByIndex(_depts, _dept);
                    setDepts(_depts);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('DeptUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.response.data}`);
                })
            setDept(deptsModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }



    //Add----
    const addDeptData = () => {
        try {
            debugger;
            paymentTables.forEach(element => {
                addDept(element)
                    .then(data => {
                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('DeptAddSuccess')}`, life: 3000 });
                    })
                    .catch(err => {

                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptAddError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.response.data}`);
                    })

            });


            filtre();
            setDept(deptsModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    //silme onayı btn----
    const deleteDeptData = () => {
        try {
            let _dept = { ...dept };

            if (depts.some(val => val.id === _dept.id)) {
                deleteDept(_dept.id)
                    .then(data => {
                        filterDepts(filter)
                            .then(data => { setDepts(data.data); setTotalRecords(data.totalRecords) })
                            .catch(err => {

                                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('DeptDeleteError') + ': ' + err.response.data}`, life: 5000 });

                            })

                        toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('DeptDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setDept(deptsModel);
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptDeleteError') + ': ' + err.response.data}`, life: 5000 });

                        //throw new Error(`${err.response.data}`);
                    })

            }

            setDept(deptsModel);
            setDeptDeleteDialog(false);

        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    const deletePaymentRowData = () => {

        setPaymentTables((paymentTables) => paymentTables.filter((_, index) => index !== 0));
    }

    const convertDate = (value) => {

        let newDate = new Date()
        newDate = value;
        let date = ("0" + newDate.getDate()).slice(-2);
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let year = newDate.getFullYear("yyyy");
        let thisDay = year + "-" + month + "-" + date;

        return thisDay;
    }

    const filtre = () => {
        debugger;
        if (selectSubAccountsFilterValue === null || selectSubAccountsFilterValue === undefined) {
            filter.subAccountId = 0;
        }
        else if (selectSubAccountsFilterValue != null) {
            filter.subAccountId = selectSubAccountsFilterValue.code;
            filter.subAccountName = selectSubAccountsFilterValue.name;
        }
        if (selectMainAccountsValue === null || selectMainAccountsValue.undefined) {
            filter.mainAccountId = 0;
        }
        else if (selectMainAccountsValue != null) {
            filter.mainAccountId = selectMainAccountsValue.code;
        }

        if (filterStartDate !== null) {
            filter.startDate = convertDate(filterStartDate);
        }
        else {
            filter.startDate = "0001-01-01T00:00:00";
        }

        if (filterEndDate !== null) {
            filter.endDate = convertDate(filterEndDate);
        }
        else {
            filter.endDate = "0001-01-01T00:00:00";
        }

        filter.showCreditCard = filterCreditCardChecked;
        toggleProgressBar(true);
        filterDepts(filter)
            .then(data => {
                debugger;


                if (data.data[0] === undefined || data.data[0].id == 0) {
                    setDepts([]);
                } else {
                    debugger;
                    let sortedData = data.data.sort((a, b) => {
                        console.log(a.issueDate);
                        // Burada sıralamak istediğin alana göre bir karşılaştırma yap
                        // Örneğin id alanına göre sıralama:
                        return new Date(a.issueDate) - new Date(b.issueDate); // Küçükten büyüğe sıralama
                        // Eğer büyükten küçüğe sıralamak istersen: b.id - a.id
                    });

                    setDepts(sortedData);
                }
                setTotalRecords(data.totalRecords);
                toggleProgressBar(false);
                if (data.data[0] !== undefined) {
                    debugger;
                    setDeptsTotal(data.data[0]);

                    // let NetPremiumUsdtoTL = data.data[0].totalPageNetPremiumUsd * currencyUSD;
                    // let NetPremiumEurtoTL = data.data[0].totalPageNetPremiumEur * currencyEUR;

                    // let ServiceFeeUsdtoTL = data.data[0].totalPageServiceFeeUsd * currencyUSD;
                    // let ServiceFeeEurtoTL = data.data[0].totalPageServiceFeeEur * currencyEUR;

                    // let DebtUsdtoTL = data.data[0].totalPageDebtUsd * currencyUSD;
                    // let DebtEurtoTL = data.data[0].totalPageDebtEur * currencyEUR;

                    // let CreditorUsdtoTL = data.data[0].totalPageCreditorUsd * currencyUSD;
                    // let CreditorEurtoTL = data.data[0].totalPageCreditorEur * currencyEUR;

                    // let BalanceUsdtoTL = data.data[0].totalPageBalanceUsd * currencyUSD;
                    // let BalanceEurtoTL = data.data[0].totalPageBalanceEur * currencyEUR;

                    // fillDeptTotalModel(data.data[0]);

                    // deptsTotalModel.totalPageNetPremiumSumTl = NetPremiumUsdtoTL + NetPremiumEurtoTL + data.data[0].totalPageNetPremiumTl;
                    // deptsTotalModel.totalPageServiceFeeSumTl = ServiceFeeUsdtoTL + ServiceFeeEurtoTL +  data.data[0].totalPageServiceFeeTl;
                    // deptsTotalModel.totalPageDebtSumTl = DebtUsdtoTL + DebtEurtoTL +  data.data[0].totalPageServiceTl;
                    // deptsTotalModel.totalPageCreditorSumTl = CreditorUsdtoTL + CreditorEurtoTL + data.data[0].totalPageCreditorTl; 
                    // deptsTotalModel.totalPageBalanceSumTl = BalanceUsdtoTL + BalanceEurtoTL + data.data[0].totalPageBalanceTl;

                    // setDeptsTotal(deptsTotalModel);

                }
                else {
                    setDeptsTotal(deptsTotalModel);
                }
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('FiltreListGettingError') + ': ' + err.response.data}`, life: 5000 });
                toggleProgressBar(false);
            })
    }

    const filtreExport = async () => {
        setPdfReady(false); // İlk başta PDF'i engelle
        if (selectSubAccountsFilterValue === null || selectSubAccountsFilterValue === undefined) {
            filter.subAccountId = 0;
        }
        else if (selectSubAccountsFilterValue != null) {
            filter.subAccountId = selectSubAccountsFilterValue.code;
            filter.subAccountName = selectSubAccountsFilterValue.name;
        }
        if (selectMainAccountsValue === null || selectMainAccountsValue.undefined) {
            filter.mainAccountId = 0;
        }
        else if (selectMainAccountsValue != null) {
            filter.mainAccountId = selectMainAccountsValue.code;
        }

        if (filterStartDate !== null) {
            filter.startDate = convertDate(filterStartDate);
        }
        else {
            filter.startDate = "0001-01-01T00:00:00";
        }

        if (filterEndDate !== null) {
            filter.endDate = convertDate(filterEndDate);
        }
        else {
            filter.endDate = "0001-01-01T00:00:00";
        }

        filter.showCreditCard = filterCreditCardChecked;
        toggleProgressBar(true);
        var filterExport = filter;
        filterExport.pageSize = 1500;
        try {
            const data = await filterDepts(filterExport);
            if (data.data[0] === undefined || data.data[0].id == 0) {
                setDeptsExport([]);
            } else {

                let sortedData = data.data.sort((a, b) => {
                    console.log(a.issueDate);
                    // Burada sıralamak istediğin alana göre bir karşılaştırma yap
                    // Örneğin id alanına göre sıralama:
                    return new Date(a.issueDate) - new Date(b.issueDate); // Küçükten büyüğe sıralama
                    // Eğer büyükten küçüğe sıralamak istersen: b.id - a.id
                });
                setDeptsTotal(data.data[0]);
                setDeptsExport(sortedData);
            }
            setTotalRecords(data.totalRecords);
            toggleProgressBar(false);
            setPdfReady(true); // PDF hazır olduğunda true yap
        } catch (err) {
            toast.current.show({
                severity: 'error',
                summary: `${t('Error')}`,
                detail: `${t('FiltreListGettingError') + ': ' + err.response.data}`,
                life: 5000
            });
            toggleProgressBar(false);
            setPdfReady(false); // Hata durumunda false yap
        }
    };

    // const fillDeptTotalModel = (model) => {

    //     deptsTotalModel.totalPageBalanceTl = model.totalPageBalanceTl;
    //     deptsTotalModel.totalPageBalanceUsd = model.totalPageBalanceUsd;
    //     deptsTotalModel.totalPageBalanceEur = model.totalPageBalanceEur;
    //     deptsTotalModel.totalPageBalanceSumTl = model.totalPageBalanceSumTl;

    //     deptsTotalModel.totalPageServiceFeeTl = model.totalPageServiceFeeTl;
    //     deptsTotalModel.totalPageServiceFeeUsd = model.totalPageServiceFeeUsd;
    //     deptsTotalModel.totalPageServiceFeeEur = model.totalPageServiceFeeEur;

    //     deptsTotalModel.totalPageNetPremiumTl = model.totalPageNetPremiumTl;
    //     deptsTotalModel.totalPageNetPremiumUsd = model.totalPageNetPremiumUsd;
    //     deptsTotalModel.totalPageNetPremiumEur = model.totalPageNetPremiumEur;

    //     deptsTotalModel.totalPageCreditorTl = model.totalPageCreditorTl;
    //     deptsTotalModel.totalPageCreditorUsd = model.totalPageCreditorUsd;
    //     deptsTotalModel.totalPageCreditorEur = model.totalPageCreditorEur;

    //     deptsTotalModel.totalPageDebtTl = model.totalPageDebtTl;
    //     deptsTotalModel.totalPageDebtUsd = model.totalPageDebtUsd;
    //     deptsTotalModel.totalPageDebtEur = model.totalPageDebtEur;
    // }

    const onPageChange = (event) => {
        ////debugger;
        setFirst(event.first);
        setRows(event.rows);
        filter.pageNumber = event.page + 1;
        filter.pageSize = event.rows;
        filtre();
    };



    //**************************************
    //Logic fonks. START
    //**************************************

    const dataTypeBodyTemplate1 = (rowData) => {
        // 1	Excel
        // 2	API
        // 3	User 

        let aa = "";
        if (rowData.dataInsertTypeId === 1) {
            aa = "Excel"
        }
        else if (rowData.dataInsertTypeId === 2) {
            aa = "API"
        }
        else if (rowData.dataInsertTypeId === 3) {
            aa = "User"
        }

        return aa;
    }

    const onMovementChange = (e) => {

        setSelectedMovementType(e.value);
    }

    const dateBodyTemplate1 = (rowData) => {

        return formatDate(rowData.createdDate);
    }
    const dateBodyTemplate2 = (rowData) => {

        return formatDate(rowData.issueDate);
    }
    const dateBodyTemplate3 = (rowData) => {

        return formatDate(rowData.policyStartDate);
    }
    const dateBodyTemplate4 = (rowData) => {

        return formatDate(rowData.policyEndDate);
    }
    const dateBodyTemplate5 = (rowData) => {

        return formatDate(rowData.deptCurrencyDate);
    }



    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }

    const sendSelectedLogs = () => {
        debugger;
        setSendLogsDialog(false);
        let selectedLogsIds = selectedLogs.map((arr, i) => arr.id);

        refundDeptsToPool(selectedLogsIds)
            .then(data => {
                filterDepts(filter)
                    .then(data => { setDepts(data.data); setTotalRecords(data.totalRecords) })
                    .catch(err => {

                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 5000 });

                    })

                toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('sendLogsToDebtSuccess')}`, life: 3000 });
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Logs sended', life: 3000 });

            })
            .catch(err => {
                ////debugger;

                setSelectedLogs(null);
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('sendLogsToDebtError') + ': ' + err.response.data}`, life: 5000 });
                //throw new Error(`${err.data}`);
            })



        setSelectedLogs(null);
    }

    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < depts.length; i++) {
                if (depts[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {

            setDept(deptsModel);
            setSubmitted(false);
            setDeptSaveDialog(true);
            deptForm_Clear();
        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setDept(deptsModel);
            setSubmitted(false);
            setError(error);
        }
    }

    //gorup adı güncelleme btn------
    const editPool = (dept) => {
        try {
            ////debugger;
            let _dept = { ...dept };
            setSubmitted(false);

            getDeptById(_dept.id)
                .then(data => {

                    for (let index = 0; index < subAccounts.length; index++) {
                        const element = subAccounts[index];

                        if (element.code === data.subAccountId) {
                            setSelectSubAccountsValue({ name: element.name, code: parseInt(element.code) });
                            if (data.customerTypeId === 1) {
                                setCustomerTypeTcShow('show field col-2');
                                setCustomerTypeTaxShow('hidden field col-2');
                            }
                            else if (data.customerTypeId === 2) {
                                setCustomerTypeTaxShow('show field col-2');
                                setCustomerTypeTcShow('hidden field col-2');
                            }
                        }
                    }

                    for (let index = 0; index < currencyTypes.length; index++) {
                        const elementA = currencyTypes[index];

                        if (elementA.name === data.currencyType) {
                            setSelectCurrencyTypesValue({ name: elementA.name, code: parseInt(elementA.code) });
                        }
                    }
                    ////debugger;
                    for (let index = 0; index < insuranceProducts.length; index++) {
                        const elementB = insuranceProducts[index];

                        if (elementB.productsCode === data.insuranceProductCode) {
                            setSelectInsuranceProductsValue({ name: elementB.name, code: parseInt(elementB.code), productsCode: elementB.productsCode });
                        }
                    }

                    data.deptCurrencyDate = new Date(data.deptCurrencyDate);

                    getProductProportionsListBySubAccountId(data.subAccountId)
                        .then(data => {
                            setProductProportions(data);
                        })
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetProductProportionsListBySubAccountIdError') + ': ' + err.response.data}`, life: 5000 });
                        })

                    setDept(data);
                })
                .catch(err => {
                    //
                    setDept(deptsModel);
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptUpdatedError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.response.data}`);
                })

            setDeptSaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('DeptUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }


    const confirmDeleteDept = (dept) => {
        try {

            setDept(dept);
            setDeptDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setDept(deptsModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setDept(deptsModel);
            setSubmitted(false);
            setDeptDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteDept-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setDept(deptsModel);
            setSubmitted(false);
            setDeptSaveDialog(false);
            deptForm_Clear();

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const hideSendLogsDialog = () => {
        setSendLogsDialog(false);
    }

    const hidePaymentRowDeleteDialog = () => {
        try {

            setPaymentRowDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeleteSubAccount-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }




    const formatInput = (e) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        let checkIfNum;
        if (e.key !== undefined) {
            // Check if it's a "e", ",", "+" or "-"
            checkIfNum = e.key === "e" || e.key === "," || e.key === "+" || e.key === "-";
        }
        else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (188), "+" (187) or "-" (189)
            checkIfNum = e.keyCode === 69 || e.keyCode === 188 || e.keyCode === 187 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    }


    //**------
    const onInputChange = (e, name) => {
        try {


            const val = (e.target && e.target.value) || '';
            let _dept = { ...dept };
            _dept[`${name}`] = val;

            setDept(_dept);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    const onInputFilterChange = (e, name) => {
        try {
            const val = (e.target && e.target.value) || '';
            let _filter = { ...filter };
            _filter[`${name}`] = val;

            setFilter(_filter);

        } catch (error) {
            error.message = error.message + '.  UI:InputFilterChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    const onSubAccountChange = (e, name) => {
        debugger;
        try {

            let subAccountTaxNumber = '0';
            let subAccountTcNumber = '0';
            const val = (e.target && e.target.value) || '';

            if (val !== "") {

                setSelectSubAccountsValue(e.value)
                //apiden currency'i çek. | deptCurrencyRate  
                getSubAccountById(val.code)
                    .then(data => {
                        let _dept = { ...dept };

                        _dept[`${'customerTypeId'}`] = data.customerTypeId;

                        if (data.customerTypeId === 1) {

                            subAccountTcNumber = data.tc
                            _dept[`${'subAccountTcNumber'}`] = subAccountTcNumber;
                            _dept[`${'subAccountTaxNumber'}`] = '';
                            // setCustomerTypeTcShow('show field col-2');
                            // setCustomerTypeTaxShow('hidden field col-2');
                        }
                        else if (data.customerTypeId === 2) {

                            subAccountTaxNumber = data.taxNo
                            _dept[`${'subAccountTaxNumber'}`] = subAccountTaxNumber;
                            _dept[`${'subAccountTcNumber'}`] = '';
                            // setCustomerTypeTcShow('hidden field col-2');
                            // setCustomerTypeTaxShow('show field col-2');
                        }

                        setDept(_dept);
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetSubAccountByIdError') + ': ' + err.response.data}`, life: 5000 });
                    })

                getProductProportionsListBySubAccountId(val.code)
                    .then(data => {
                        setProductProportions(data);
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('GetProductProportionsListBySubAccountIdError') + ': ' + err.response.data}`, life: 5000 });
                    })

            }
            else {
                let _dept = { ...dept };

                setSelectSubAccountsValue(null);
                _dept[`${'subAccountTcNumber'}`] = '';
                _dept[`${'subAccountTaxNumber'}`] = '';
                setDept(_dept);
            }
        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onMainAccountChange = (e, name) => {
        //debugger;
        try {

            const val = (e.target && e.target.value) || '';


            setSelectMainAccountsValue(e.value);

            setSubAccounts([]);

            getSubAccountListByMainAccountId(val.code)
                .then(data => {
                    setSubAccounts(data.map(function (item, i) {
                        return { name: item.name, code: parseInt(item.id) };
                    }));
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('SubAccountsListGettingError') + ': ' + err.response.data}`, life: 5000 });
                })


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onMainAccountFilterChange = (e, name) => {
        //debugger;
        try {

            const val = (e.target && e.target.value) || '';


            setSelectMainAccountsValue(e.value);
            setSubAccountsforFilter([]);
            // setSubAccounts()
            if (val !== "") {
                getSubAccountListByMainAccountId(val.code)
                    .then(data => {
                        setSubAccountsforFilter(data.map(function (item, i) {
                            return { name: item.name, code: parseInt(item.id) };
                        }));
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('SubAccountsListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    })
            }

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onInsuranceProductsChange = (e, name) => {

        try {
            ////debugger;
            const val = (e.target && e.target.value) || '';
            let subAccountProductCommPer = '';

            setSelectInsuranceProductsValue(e.value)

            //currencyType 1 TL
            //currencyType 2 USD
            //currencyType 5 EUR 

            productProportions.forEach(element => {
                if (element.insuranceProductId === val.code) {
                    if (dept.deptCurrencyTypeId === 1 || dept.deptCurrencyTypeId === null) {
                        subAccountProductCommPer = element.tl;
                    }
                    else if (dept.deptCurrencyTypeId === 2) {
                        subAccountProductCommPer = element.usd;
                    }
                    else if (dept.deptCurrencyTypeId === 3) {
                        subAccountProductCommPer = element.eu;
                    }


                    let _dept = { ...dept };
                    _dept[`${'subAccountProductCommPer'}`] = subAccountProductCommPer;

                    setDept(_dept);
                }
            });



        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //PoolLog Clear
    const deptForm_Clear = () => {

        setSelectedMovementType(null);
        setSelectMainAccountsValue(null);
        setSelectSubAccountsValue(null);
        setDeptCurrencyDate(null);
        setSelectDeptCurrencyTypesValue(null);
        setSelectCurrencyTypesValue(null);
        setSelectPaymentTypesValue(null);
        setPaymentTables([]);
        // setDept(null); 

    }
    function add12Hours(date) {
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() + 12);
        return newDate;
    }
    const onPreview = () => {
        debugger;
        try {

            let validStatus = validForm("onizlemeBtn");
            setIsSubmitted(validStatus);
            if (validStatus) {

                let description = "";
                let caprazTutar = "";
                let islenecekTutar = 0;


                let _dept = { ...dept };

                //Gelen Tutar TL ise gelen tutarı kur'a böl.
                if (selectDeptCurrencyTypesValue.code === 1) {

                    islenecekTutar = _dept.incomingAmount / _dept.currencyRate;
                    islenecekTutar = islenecekTutar.toFixed(2);

                    description = "Gelen Dov. Tar: " + formatDate(deptCurrencyDate) + " - Ödeme Tipi - " + selectPaymentTypesValue.name + ": " + _dept.incomingAmount + selectDeptCurrencyTypesValue.name + " / " + _dept.deptCurrencyRate + "(" + selectCurrencyTypesValue.name + ")";

                }//Gelen tutar Eu veya Usd ise 
                else if ((selectCurrencyTypesValue.code === 3 && selectDeptCurrencyTypesValue.code === 2) || (selectCurrencyTypesValue.code === 2 && selectDeptCurrencyTypesValue.code === 3)) {

                    caprazTutar = _dept.incomingAmount * _dept.deptCurrencyRate;
                    caprazTutar = caprazTutar.toFixed(2);
                    islenecekTutar = caprazTutar / _dept.currencyRate;
                    islenecekTutar = islenecekTutar.toFixed(2);

                    description = "Gelen Dov. Tar: " + formatDate(deptCurrencyDate) + " - Ödeme Tipi - " + selectPaymentTypesValue.name + ": " + _dept.incomingAmount + selectDeptCurrencyTypesValue.name +
                        " * " + _dept.deptCurrencyRate + "(" + selectDeptCurrencyTypesValue.name + ")" + caprazTutar + "TL / " + _dept.currencyRate + "(" + selectCurrencyTypesValue.name + " )";
                }
                else if ((selectDeptCurrencyTypesValue.code === 2 && selectCurrencyTypesValue.code === 1) || (selectDeptCurrencyTypesValue.code === 3 && selectCurrencyTypesValue.code === 1)) {

                    islenecekTutar = _dept.incomingAmount * _dept.deptCurrencyRate;
                    islenecekTutar = islenecekTutar.toFixed(2);

                    description = "Gelen Dov. Tar: " + formatDate(deptCurrencyDate) + " - Ödeme Tipi - " + selectPaymentTypesValue.name + ": " + _dept.incomingAmount + selectDeptCurrencyTypesValue.name +
                        " * " + _dept.deptCurrencyRate + "(" + selectCurrencyTypesValue.name + ")";
                }
                else if ((selectDeptCurrencyTypesValue.code === 2 && selectCurrencyTypesValue.code === 2) || (selectDeptCurrencyTypesValue.code === 3 && selectCurrencyTypesValue.code === 3)) {
                    islenecekTutar = _dept.incomingAmount;

                    description = "Gelen Dov. Tar: " + formatDate(deptCurrencyDate) + " - Ödeme Tipi - " + selectPaymentTypesValue.name + ": " + _dept.incomingAmount + selectCurrencyTypesValue.name + "(" + selectDeptCurrencyTypesValue.name + ")";
                }

                if (selectedMovementType.code === 2)//alacak tipi ise eksi atılacak.
                {
                    islenecekTutar = islenecekTutar * -1;
                }

                ////debugger; 


                _dept.subAccountId = selectSubAccountsValue.code;
                _dept.paymentTypeId = selectPaymentTypesValue.code;
                _dept.description = description;
                _dept.dataInsertTypeId = 3;//user
                _dept.deptCurrencyTypeId = selectDeptCurrencyTypesValue.code;
                _dept.currencyType = selectCurrencyTypesValue.name;
                _dept.deptCurrencyRate = dept.deptCurrencyRate;
                _dept.deptCurrencyDate = add12Hours(deptCurrencyDate);
                _dept.movementAmount = islenecekTutar;
                // _dept.grossPremium = islenecekTutar;
                // _dept.netPremium = islenecekTutar;
                _dept.incomingAmount = dept.incomingAmount;
                _dept.movementTypeId = selectedMovementType.code;
                _dept.movementTypeName = selectedMovementType.name;
                _dept.subAccountName = selectSubAccountsValue.name;
                _dept.deptCurrencyType = selectDeptCurrencyTypesValue.name;
                _dept.currencyRate = dept.currencyRate;
                _dept.currencyDate = add12Hours(currencyDate);
                _dept.deptCurrencyTypeId = selectDeptCurrencyTypesValue.code;
                _dept.issueDate = add12Hours(currencyDate);




                _dept.policyNumber = '1';
                _dept.addendumNo = 1;
                _dept.insuranceProductId = null;

                setDept(_dept);
            }
        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    const onAddRow = () => {

        try {
            ////debugger;
            let validStatus = validForm();
            setIsSubmitted(validStatus);
            if (validStatus) {
                let _dept = { ...dept };

                setPaymentTables(paymentTables => [...paymentTables, _dept])

                setDept(_dept);
            }

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    const onCurrencyChange = (e, name) => {

        try {
            ////debugger;
            const val = (e.target && e.target.value) || '';
            let currencyValue = '';
            let currencyTypeId = '';

            setSelectCurrencyTypesValue(e.value);

            let newDate = new Date()
            newDate = deptCurrencyDate;
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let thisDay = year + "-" + month + "-" + date;


            if (val.code === 2 || val.code === 3) {

                //apiden currency'i çek. | deptCurrencyRate  
                getCurrenciesByDate(thisDay, val.code)
                    .then(data => {

                        currencyValue = data.rate;
                        currencyTypeId = val.code;

                        let _dept = { ...dept };
                        _dept[`${'currencyRate'}`] = currencyValue;
                        _dept[`${'currencyTypeId'}`] = currencyTypeId;

                        setDept(_dept);
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CurrencyTypesListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    })

            }
            else {
                let _dept = { ...dept };
                _dept[`${'currencyRate'}`] = 1;
                _dept[`${'currencyTypeId'}`] = 1;

                setDept(_dept);
            }


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onDeptCurrencyChange = (e, name) => {

        try {
            debugger;
            const val = (e.target && e.target.value) || '';
            let deptCurrencyValue = '';
            let deptCurrencyTypeId = '';

            setSelectDeptCurrencyTypesValue(e.value);

            let newDate = new Date()
            newDate = deptCurrencyDate;
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let thisDay = year + "-" + month + "-" + date;

            if (val.code === 2 || val.code === 3) {

                //apiden currency'i çek. | deptCurrencyRate  
                getCurrenciesByDate(thisDay, val.code)
                    .then(data => {


                        deptCurrencyValue = data.rate;
                        deptCurrencyTypeId = val.code;

                        let _dept = { ...dept };
                        _dept[`${'deptCurrencyRate'}`] = deptCurrencyValue;
                        _dept[`${'deptCurrencyTypeId'}`] = deptCurrencyTypeId;

                        setDept(_dept);
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${t('CurrencyTypesListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    })

            }
            else {
                let _dept = { ...dept };
                _dept[`${'deptCurrencyRate'}`] = 1;
                _dept[`${'deptCurrencyTypeId'}`] = 1;

                setDept(_dept);
            }


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onInputDeptCurrencyDateChange = (e, name) => {
        debugger;
        try {

            const val = (e.target && e.target.value) || '';
            let _dept = { ...dept };
            _dept[`${name}`] = val;

            if (val === "") {
                setIsInputDisabled(true);
                setSelectDeptCurrencyTypesValue(null);
                setSelectCurrencyTypesValue(null);
                _dept.deptCurrencyRate = 0;
                _dept.currencyRate = 0;
            }
            else {
                setIsInputDisabled(false);
                setSelectDeptCurrencyTypesValue(null);
                setSelectCurrencyTypesValue(null);
                _dept.deptCurrencyRate = 0;
                _dept.currencyRate = 0;
            }

            setDeptCurrencyDate(e.value);
            setDept(_dept);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const sendSelectedLogsElementer = () => {
        debugger;
        setSendLogsDialogElementer(false);
        let selectedLogsIds = selectedLogs.map((arr, i) => arr.id);

        updateElementersStatus(selectedLogsIds)
            .then(data => {
                toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('updateElementerStatusSuccess')}`, life: 3000 });

                filterDepts(filter)
                    .then(data => { setDepts(data.data); setTotalRecords(data.totalRecords) })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('filterListEror') + ': ' + err.response.data}`, life: 5000 });
                    })
            })
            .catch(err => {

                setSelectedLogs(null);
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('sendLogsToDebtError') + ': ' + err.response.data}`, life: 5000 });
                //throw new Error(`${err.data}`);
            })



        setSelectedLogs(null);
    }


    //**************************************
    //btn fonks. END
    //**********************



    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const deleteDeptDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deleteDeptData} />
        </>
    );

    const deletePaymentRowDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hidePaymentRowDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deletePaymentRowData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('DebtList')} </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );



    const verifiedBodyTemplate = (rowData) => {

        if (rowData.movementTypeId === 1) {

            if (rowData.insuranceProduct === undefined || rowData.insuranceProduct === null || rowData.insuranceProduct.elementer === false) {
                return <i className={classNames('pi text-pink-500 pi-times-circle')}></i>;
            }
            else if (rowData.insuranceProduct.elementer === true) {
                return <i className={classNames('pi text-green-500 pi-check-circle')}></i>;
            }
        }
        else {
            return "";
        }
    }

    const statusBodyTemplate = (rowData) => {

        if (rowData.movementTypeId === 1) {

            if (rowData.policyElementerStatusId === undefined || rowData.policyElementerStatusId === null || rowData.policyElementerStatusId === 0
                || rowData.policyElementerStatusId === 2) {
                return <i className={classNames('pi text-pink-500 pi-times-circle')}></i>;
            }
            else if (rowData.policyElementerStatusId === 1) {
                return <i className={classNames('pi text-green-500 pi-check-circle')}></i>;
            }
        }
        else {
            return "";
        }
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {/* {(() => {
                        if (rowData.movementTypeId === 2) {
                            if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                                return (
                                    <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success mr-2 ml-2 mr-2" onClick={() => editPool(rowData)} />
                                )
                            }
                        }

                        return null;
                    })()} */}
                    {/* movementTypeId === 2 ALACAK 3 BORÇ //// dataInsertTypeId === 3 USER*/}
                    {(() => {
                        if (rowData.movementTypeId === 2 || rowData.dataInsertTypeId === 3) {
                            if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                                return (
                                    <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger mt-2 ml-2 mr-2" onClick={() => confirmDeleteDept(rowData)} />
                                )
                            }
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const KalanYetkiKontrol = (rowData) => {
        //debugger; 
        return (
            <>
                <div className="actions">
                    {(() => {

                        if (JwtHelper.isRoleExist('GetSeeProfitClaimQuery')) {
                            return (
                                <span> {formatCurrency(rowData.companyComm, rowData.currencyType.name)} </span>
                            )
                        }
                        else {
                            return (
                                <div className="tooltips" data-pr-tooltip="Bu alanı görmek yetki gerektirir: Daha fazla yetki için sistem yöneticisi ile görüşebilirsiniz. Yetki Key: GetSeeProfitClaimQuery">
                                    <Tooltip target=".tooltips" />
                                    <span  > Y.G. </span>
                                </div>
                            )

                        }
                    })()}
                </div>
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        const maxLength = 20; // Gösterilecek maksimum karakter sayısı
        const description = rowData.description || ""; // Eğer description null veya undefined ise boş string kullan
    
        const shortDescription = description.length > maxLength 
            ? description.substring(0, maxLength) + "..." 
            : description;
    
        return (
            <div>
                <span id={`descTooltip-${rowData.id}`}>{shortDescription}</span>
                {description.length > maxLength && (
                    <Tooltip target={`#descTooltip-${rowData.id}`} content={description} />
                )}
            </div>
        );
    };

    const subAccountBodyTemplate = (rowData) => {
        const maxLength = 20; // Gösterilecek maksimum karakter sayısı
        const subAccountName = rowData.subAccount?.name || ""; // Eğer subAccount veya name null/undefined ise boş string kullan
    
        const shortSubAccountName = subAccountName.length > maxLength 
            ? subAccountName.substring(0, maxLength) + "..." 
            : subAccountName;
    
        return (
            <div>
                <span id={`subAccountTooltip-${rowData.id}`}>{shortSubAccountName}</span>
                {subAccountName.length > maxLength && (
                    <Tooltip target={`#subAccountTooltip-${rowData.id}`} content={subAccountName} />
                )}
            </div>
        );
    };

    const confirmSendSelectedLogs = () => {
        setSendLogsDialog(true);
    }

    const onRowEditComplete = (e) => {
        ////debugger;
        // agencyOfficer.id = e.newData.id;
        // agencyOfficer.companyId = e.newData.companyId;
        // agencyOfficer.subAccountId = e.newData.subAccountId;
        // agencyOfficer.firstName = e.newData.firstName;
        // agencyOfficer.lastName = e.newData.lastName;
        // agencyOfficer.title = e.newData.title;
        // agencyOfficer.email = e.newData.email;
        // agencyOfficer.phoneNumber = e.newData.phoneNumber;

        // updateAgencyOfficersData();

        // clearAgencyOfficer();
    }

    const confirmDeletePaymentRow = (row) => {
        try {

            ////debugger;
            // setPaymentTables((paymentTables) => paymentTables.filter((_, index) => index !== 0));
            let _paymentTables = paymentTables.filter(val => val !== row);
            setPaymentTables(_paymentTables);

            // setPaymentRowDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);
        }

    }


    const actionBodyDeptPaymentTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button p-component p-button-rounded p-button-danger p-button-text p-button-icon-only" onClick={() => confirmDeletePaymentRow(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }


    // const confirmSendSelectedLogs = () => {
    //     debugger;
    //     setSendLogsDialog(true);
    // }

    const confirmSendSelectedLogsElementer = () => {
        setSendLogsDialogElementer(true);
    }


    const hideSendLogsDialogElementer = () => {
        setSendLogsDialog(false);
    }

    const sendLogsDialogFooterElementer = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideSendLogsDialogElementer} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sendSelectedLogsElementer} />

        </>
    );
    const toggleProgressBar = (showProgressBar) => {
        setShowProgressBar(showProgressBar);
    };

    const items = [
        {
            label: t('Elementer Durumu Değiştir'),
            icon: 'pi pi-refresh',
            command: confirmSendSelectedLogsElementer,
            disabled: !selectedLogs || !selectedLogs.length
        },
        {
            label: t('ReturntoPool'),
            icon: 'pi pi-send',
            command: confirmSendSelectedLogs,
            disabled: !selectedLogs || !selectedLogs.length
        },
    ];


    const leftToolbarTemplate = () => {

        return (
            <>
                <Button label={t('Filtrele')} icon="pi pi-filter" className="p-button-warning mr-2" onClick={filtre} />
            </>
        )
    }

    const centerToolbarFiltring = () => {

        const pdfName = filter.subAccountName.replaceAll('.', '_');
        debugger;
        return (
            <>
                {(() => {

                    return (
                        <div>
                            <div className="flex md:align-items-center">
                                <div className="my-2 ml-2">

                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            <Toast ref={toast}></Toast>
                                            <SplitButton label={t('Add')} icon="pi pi-plus" onClick={openNew} model={items} severity="success" className="p-button-success mr-2" />
                                        </div>
                                    </React.Fragment>
                                </div>
                                <div className="my-2 ml-2">
                                    <Checkbox className="mr-2" onChange={e => setPdfGuncelKurDahil(e.checked)} checked={pdfGuncelKurDahil}></Checkbox>
                                    <label style={{ paddingTop: 4, marginRight: 10 }}>G.K. Dahil Et</label>
                                    <Button label={t('Create Export')} icon="pi pi-print" className="p-button-help mr-2" onClick={filtreExport} />

                                    {pdfReady && (
                                        <PDFDownloadLink className="mr-2"
                                            document={<DeptsToPdf data={deptsExport} data2={deptsTotal} data3={filter} data4={pdfGuncelKurDahil} dollarRate={currencyUSD} euroRate={currencyEUR} />}
                                            fileName={pdfName + " | " + filter.startDate + "-" + filter.endDate}>
                                            {({ loading }) => (loading
                                                ? <Button label={t('Loading Document')} icon="pi pi-print" className="p-button-danger mr-2" />
                                                : <Button label={t('PDF Hazır')} icon="pi pi-print" className="p-button-success mr-2" />)}
                                        </PDFDownloadLink>
                                    )}

                                </div>
                                <div className="my-2 ml-2">
                                    {pdfReady && (
                                        <ExcelExport className="mr-2" data={deptsExport} data2={deptsTotal} data3={filter} data4={pdfGuncelKurDahil} dollarRate={currencyUSD} euroRate={currencyEUR} />
                                    )}

                                </div>

                            </div>

                            <hr></hr>

                            <div className="my-2" >

                                <Checkbox className="mr-2" onChange={e => setFilterCreditCardChecked(e.checked)} checked={filterCreditCardChecked}></Checkbox>

                                <Dropdown showClear filter id="mainAccountId" value={selectMainAccountsValue} options={mainAccounts} onChange={(e) => onMainAccountFilterChange(e, 'mainAccountId')} optionLabel="name" placeholder="Ana Hesap Seçin" />

                                <Dropdown showClear filter className="mr-2" id="filterSubAccountId" placeholder="Kart Seçin" value={selectSubAccountsFilterValue} options={subAccountsforFilter} onChange={(e) => setSelectSubAccountsFilterValue(e.value)} optionLabel="name" />

                                <InputText className="mr-2" id="filterSearchKey" placeholder="Aranacak Kelime" value={filter.search} onChange={(e) => onInputFilterChange(e, 'search')} />

                                <Calendar className="mr-2" id="filterStartDate" placeholder="Başlangıç Tarihi" locale="tr" dateFormat="dd.mm.yy" value={filterStartDate} onChange={(e) => setFilterStartDate(e.value)} />

                                <Calendar className="mr-2" id="filterEndDate" placeholder="Bitiş Tarihi" locale="tr" dateFormat="dd.mm.yy" value={filterEndDate} onChange={(e) => setFilterEndDate(e.value)} />

                            </div>
                        </div>
                    )

                })()}
            </>
        );

    }

    const grossCurrency = (rowData) => {

        return formatCurrency(rowData.grossPremium, rowData.currencyType.name);
    }
    const netCurrency = (rowData) => {

        return formatCurrency(rowData.netPremium, rowData.currencyType.name);
    }
    const incomingCurrency = (rowData) => {

        return formatCurrency(rowData.incomingComm, rowData.currencyType.name);
    }
    const serviceFeeCurrency = (rowData) => {

        return formatCurrency(rowData.serviceFee, rowData.currencyType.name);
    }
    const balanceTLCurrency = (rowData) => {

        return formatCurrency(rowData.balanceTl, "TRY");
    }
    const balanceUsdCurrency = (rowData) => {

        return formatCurrency(rowData.balanceUsd, "Usd");
    }
    const balanceEurCurrency = (rowData) => {

        return formatCurrency(rowData.balanceEur, "Eur");
    }


    const formatCurrency = (value, currencyType = "TRY") => {

        if (value === undefined) {
            return 0;
        }

        return value.toLocaleString('tr-TR', { style: 'currency', currency: currencyType }).replace(',', '.');
    };

    const sendLogsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideSendLogsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sendSelectedLogs} />

        </>
    );
    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }


    //movementTypeId === 1 borç
    //movementTypeId === 2 alacakk
    // const rowClass = (data) => {
    //     ////debugger;
    //     return {           
    //         'bg-red-100': data.movementTypeId === 1
    //     };
    // };

    const borcAlacakBody = (rowData) => {
        debugger;
        const stockClassName = classNames('border-square w-6rem h-4rem inline-flex font-bold justify-content-center align-items-center text-sm', {
            'bg-teal-100 text-teal-900': rowData.movementTypeId === 1,
            'bg-blue-100 text-blue-900': rowData.movementTypeId === 2
        });

        return <div className={stockClassName + " tooltips"} data-pr-tooltip={rowData.movementTypeId === 1 ? "Borç" : "Alacak"}>

            <Tooltip target=".tooltips" />
            {formatCurrency(rowData.movementAmount, rowData.currencyType.name)}

        </div>;
    }

    const dovizBody = (rowData) => {
        const stockClassName = classNames('border-square w-6rem h-4rem inline-flex font-bold justify-content-center align-items-center text-sm', {
            'bg-blue-300 text-blue-900': rowData.currencyTypeId === 1, //tl
            'bg-green-300 text-green-900': rowData.currencyTypeId === 2, // 'USD',
            'bg-yellow-300 text-yellow-900': rowData.currencyTypeId === 3 //'EUR',
        });

        return <div className={stockClassName}>
            {rowData.currencyTypeId === 1 ? "TL" : rowData.currencyTypeId === 2 ? "USD" : "EUR"}
        </div>;
    };

    const pageBottomButtons = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const handleSelectionChange = (e) => {
        setSelectedLogs(e.value);
        console.log("Seçilen kayıtlar:", e.value);
        // Burada seçilen kayıtlarla ilgili işlemleri yapabilirsiniz
    };

    return (


        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>

                                <ul className="list-none p-0 m-0 flex align-items-center font-medium">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('DebtTitle')}</span>
                                    </li>
                                    <li className="px-2">
                                        |
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('HomePage')} </span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('DebtTitle')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900"> {t('DebtList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-2">

                                <DbCurrencies />

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="card">
                        <Panel header="Hesap Özeti" toggleable collapseIcon="pi pi-angle-up" expandIcon="pi pi-angle-down">
                            <div className="p-toolbar p-component mb-4">
                                <div className="col-12">
                                    <div className="grid justify-content-center">
                                        <div className="col-12 lg:col-5 xl:col-2">
                                            <div className="card mb-0">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-4">{t('Period')}</span>

                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPagePeriodTl)}
                                                            </span>
                                                            TL
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPagePeriodEur, "Eur")}
                                                            </span>
                                                            EUR
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPagePeriodUsd, "Usd")}
                                                            </span>
                                                            USD
                                                        </div>
                                                        <Divider />

                                                    </div>

                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-orange-500 text-xl" />
                                                    </div>
                                                </div>

                                                <span className="text-green-500 font-medium"> {formatCurrency(deptsTotal.totalPagePeriodEntire, "TRY") + " | (G.K.: " + formatCurrency(deptsTotal.totalPagePeriodEntireEquivalentRate, "TRY") + ")"}  </span>

                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-5 xl:col-2">
                                            <div className="card mb-0">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-4">{t('Premium')}</span>

                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {/* {formatCurrency(deptsTotal.totalPageNetPremiumTl)} */}
                                                                {formatCurrency(deptsTotal.totalPageGrossPremiumTl)}
                                                            </span>
                                                            TL
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageGrossPremiumEur, "Eur")}
                                                                {/* {formatCurrency(deptsTotal.totalPageNetPremiumEur, "Eur")} */}
                                                            </span>
                                                            EUR
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageGrossPremiumUsd, "Usd")}
                                                                {/* {formatCurrency(deptsTotal.totalPageNetPremiumUsd, "Usd")} */}
                                                            </span>
                                                            USD
                                                        </div>
                                                        <Divider />

                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-orange-500 text-xl" />
                                                    </div>
                                                </div>
                                                {/* <span className="text-green-500 font-medium"> {formatCurrency(deptsTotal.totalPageNetPremiumEntire) + " | (G.K.: " + formatCurrency(deptsTotal.totalPageNetPremiumEntireEquivalentRate) + ")"}</span> */}
                                                <span className="text-green-500 font-medium"> {formatCurrency(deptsTotal.totalPageGrossPremiumEntire) + " | (G.K.: " + formatCurrency(deptsTotal.totalPageGrossPremiumEntireEquivalentRate) + ")"}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-5 xl:col-2">
                                            <div className="card mb-0">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-4">{t('ServiceFee')}</span>
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageServiceFeeTl)}
                                                            </span>
                                                            TL</div>
                                                        <Divider />

                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageServiceFeeEur, "Eur")}
                                                            </span>
                                                            EUR
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageServiceFeeUsd, "Usd")}
                                                            </span>
                                                            USD
                                                        </div>
                                                        <Divider />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-orange-500 text-xl" />
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">{formatCurrency(deptsTotal.totalPageServiceFeeEntire) + " | (G.K.: " + formatCurrency(deptsTotal.totalPageServiceFeeEntireEquivalentRate) + ")"}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-5 xl:col-2">
                                            <div className="card mb-0">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-500 font-medium mb-4">{t('Debt')}</span>
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageDebtTl)}
                                                            </span>
                                                            TL
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageDebtEur, "Eur")}
                                                            </span>
                                                            EUR
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageDebtUsd, "Usd")}
                                                            </span>
                                                            USD
                                                        </div>
                                                        <Divider />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-orange-500 text-xl" />
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">{formatCurrency(deptsTotal.totalPageDebtEntire) + " | (G.K.: " + formatCurrency(deptsTotal.totalPageDebtEntireEquivalentRate) + ")"}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-5 xl:col-2">
                                            <div className="card mb-0">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-4">{t('Credit')}</span>
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageCreditorTl)}
                                                            </span>
                                                            TL
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageCreditorEur, "Eur")}
                                                            </span>
                                                            EUR
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageCreditorUsd, "Usd")}
                                                            </span>
                                                            USD
                                                        </div>
                                                        <Divider />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-orange-500 text-xl" />
                                                    </div>
                                                </div>
                                                <span className="text-green-600 font-medium">{formatCurrency(deptsTotal.totalPageCreditorEntire) + " | (G.K.: " + formatCurrency(deptsTotal.totalPageCreditorEntireEquivalentRate) + ")"}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 lg:col-5 xl:col-2">
                                            <div className="card mb-0">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-500 font-medium mb-4">{t('Balance')}</span>
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageBalanceTl)}
                                                            </span>
                                                            TL</div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageBalanceEur, "Eur")}
                                                            </span>
                                                            EUR
                                                        </div>
                                                        <Divider />
                                                        <div className="text-900 font-medium mb-3 text-l">
                                                            <span className="mr-3">
                                                                {formatCurrency(deptsTotal.totalPageBalanceUsd, "Usd")}
                                                            </span>
                                                            USD
                                                        </div>
                                                        <Divider />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-orange-500 text-xl" />
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">{formatCurrency(deptsTotal.totalPageBalanceEntire) + " | (G.K.: " + formatCurrency(deptsTotal.totalPageBalanceEntireEquivalentRate) + ")"}</span>
                                            </div>
                                        </div>


                                        {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} /> */}

                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>

                </div>
            </div>
            <div className="col-12">

                <div>
                    <div className="card">
                        <Toast ref={toast} />
                        <Panel header={leftToolbarTemplate} toggleable collapsed="true" collapseIcon="pi pi-angle-up" expandIcon="pi pi-angle-down">
                            <div>{centerToolbarFiltring()}</div>
                        </Panel>
                        {showProgressBar && <ProgressBarDiv />}
                        {/* <Toolbar className="mb-4" left={centerToolbarFiltring} ></Toolbar> */}
                        { }
                        <DataTable ref={dt} value={depts} scrollable scrollHeight="620px" selection={selectedLogs} onSelectionChange={handleSelectionChange}
                            dataKey="id" className="datatable-responsive"
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header} responsiveLayout="scroll">
                            <Column selectionMode="multiple" ></Column>
                            <Column field="id" header={t('Id')} sortable ></Column>
                            <Column field="dataInsertTypeId" header={t('InsertType')} sortable body={dataTypeBodyTemplate1}></Column>
                            <Column field="createdDate" header={t('CreatedDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate1}></Column>
                            <Column field="insuranceProduct.insuranceCompany.name" header={t('Company')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="subAccount.name" header={t('SubAccountName')} sortable style={{ minWidth: '200px' }} body={subAccountBodyTemplate}></Column>
                            {/* <Column field="subAccount.name" header={t('SubAccountName')} sortable style={{ minWidth: '200px' }}></Column> */}
                            {/* <Column field="subAccountTaxNumber" header={t('VergiNo')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="subAccountTcNumber" header={t('TcNo')} sortable style={{ minWidth: '100px' }}></Column> */}
                            <Column field="insuranceByPerson" header={t('InsuranceByPerson')} sortable style={{ minWidth: '200px' }}></Column>
                            <Column field="insuredPersonName" header={t('InsuredPersonName')} sortable style={{ minWidth: '200px' }}></Column>
                            <Column field="insuredPersonTc" header={t('InsuredPersonTc')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="policyElementerStatusId" header={t('Yenilendi')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={statusBodyTemplate}></Column>
                            <Column field="elementer" header={t('Elementer')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={verifiedBodyTemplate}></Column>
                            <Column field="addendumNo" header={t('AddendumNo')} sortable   style={{ minWidth: '100px' }} bodyStyle={{ textAlign: 'right' }} ></Column>
                            <Column field="policyNumber" header={t('PolicyNumber')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="description" header={t('Description')} sortable style={{ minWidth: '100px' }} body={descriptionBodyTemplate}></Column>
                            {/* <Column field="description" header={t('Description')} sortable style={{ minWidth: '100px' }}></Column> */}
                            <Column field="issueDate" header={t('IssueDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate2} ></Column>
                            <Column field="policyStartDate" header={t('PolicyStartDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate3}></Column>
                            <Column field="policyEndDate" header={t('PolicyEndDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate4}></Column>
                            <Column field="creditCard" header={t('CreditCard')} sortable style={{ minWidth: '200px' }}></Column>
                            <Column field="tax" header={t('Tax')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="currencyType.name" header={t('CurrencyType')} sortable style={{ minWidth: '100px' }} body={dovizBody}></Column>
                            <Column field="currencyRate" header={t('CurrencyRate')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuranceProduct.name" header={t('InsuranceProductName')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuranceProduct.productCode" header={t('InsuranceProductCode')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="subAccountCommPer" header={t('SubAccountProductCommPer')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="grossPremium" header={t('GrossPremium')} sortable style={{ minWidth: '100px' }} body={grossCurrency}></Column>
                            <Column field="netPremium" header={t('NetPremium')} sortable style={{ minWidth: '100px' }} body={netCurrency}></Column>
                            <Column field="incomingComm" header={t('IncomingComm')} sortable style={{ minWidth: '100px' }} body={incomingCurrency}></Column>
                            <Column field="serviceFee" header={t('ServiceFee')} sortable style={{ minWidth: '100px' }} body={serviceFeeCurrency}></Column>
                            <Column field="movementAmount" header={t('MovementAmount')} sortable style={{ minWidth: '100px' }} body={borcAlacakBody}></Column>
                            <Column field="companyComm" header={t('CompanyComm')} sortable style={{ minWidth: '100px' }} body={KalanYetkiKontrol}></Column>
                            <Column field="balanceTl" header={t('Balance') + " TL"} style={{ minWidth: '100px' }} body={balanceTLCurrency}></Column>
                            <Column field="balanceUsd" header={t('Balance') + " Usd"} sortable style={{ minWidth: '100px' }} body={balanceUsdCurrency}></Column>
                            <Column field="balanceEur" header={t('Balance') + " Eur"} sortable style={{ minWidth: '100px' }} body={balanceEurCurrency}></Column>
                            {/* <Column field="insuranceProduct.elementer" header={t('Elementer')} dataType="boolean" bodyClassName="text-center" style={{ minWidth: '3rem' }} body={verifiedBodyTemplate}></Column> */}
                            <Column header="#" body={actionBodyTemplate} style={{ minWidth: '100px' }}></Column>
                        </DataTable>
                        <Paginator template={pageBottomButtons} first={first} rows={rows} totalRecords={totalRecords} rowsPerPageOptions={[50, 100, 150]} onPageChange={onPageChange} />


                        <Dialog visible={deptSaveDialog} style={{ width: '850px' }} header={t('DeptForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>
                            <div className="formgrid grid">
                                <div className="field col-2">
                                    <label htmlFor="movementTypeId">{t('InputType')} </label>
                                    <Dropdown id="movementTypeId" value={selectedMovementType} options={movementType} onChange={(e) => onMovementChange(e, 'movementTypeId')} optionLabel="name" placeholder="Giriş Tipi" className={classNames({ 'p-invalid': !isValidateSelectedMovementType })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="mainAccountId">{t('MainAccount')} </label>
                                    <Dropdown id="mainAccountId" value={selectMainAccountsValue} options={mainAccounts} onChange={(e) => onMainAccountChange(e, 'mainAccountId')} optionLabel="name" placeholder="Ana Hesap Seçin" className={classNames({ 'p-invalid': !isValidateSelectMainAccountsValue })} />
                                </div>
                                <div className="field col">
                                    <label htmlFor="subAccountId">{t('SubAccountName')} </label>
                                    <Dropdown id="subAccountId" showClear filter value={selectSubAccountsValue} options={subAccounts} onChange={(e) => onSubAccountChange(e, 'subAccountId')} optionLabel="name" placeholder="Alt Hesap" className={classNames({ 'p-invalid': !isValidateSelectSubAccountsValue })} />
                                </div>
                                {/* <div className={customerTypeTaxShow}>
                                    <label htmlFor="subAccountTaxNumber">{t('subAccountTaxNumber')} </label>
                                    <InputText id="subAccountTaxNumber" value={dept.subAccountTaxNumber} onChange={(e) => onInputChange(e, 'subAccountTaxNumber')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                    {submitted && !dept.subAccountTaxNumber && <small className="p-invalid">{t('Required')}</small>}
                                </div> */}
                                {/* <div className={customerTypeTcShow}>
                                    <label htmlFor="subAccountTcNumber">{t('SubAccountTcNumber')} </label>
                                    <InputText id="subAccountTcNumber" value={dept.subAccountTcNumber} onChange={(e) => onInputChange(e, 'subAccountTcNumber')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                    {submitted && !dept.subAccountTcNumber && <small className="p-invalid">{t('Required')}</small>}
                                </div> */}
                            </div>
                            <hr></hr>
                            <div className="formgrid grid">
                                <div className="field col-2">
                                    <label htmlFor="deptCurrencyDate">{t('IncomingExchangeDate')}</label>
                                    <Calendar id="deptCurrencyDate" locale="tr" dateFormat="dd.mm.yy" value={deptCurrencyDate} onChange={(e) => onInputDeptCurrencyDateChange(e, 'deptCurrencyDate')} required className={classNames({ 'p-invalid': !isValidateDeptCurrencyDate })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="deptCurrencyTypeId">{t('IncomingCurrency')}</label>
                                    <Dropdown id="deptCurrencyTypeId" value={selectDeptCurrencyTypesValue} options={deptCurrencyTypes} onChange={(e) => onDeptCurrencyChange(e, 'deptCurrencyTypeId')} optionLabel="name" placeholder="Gelen Döviz" disabled={isInputDisabled} className={classNames({ 'p-invalid': !isValidateSelectDeptCurrencyTypesValue })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="deptCurrencyRate">{t('IncomingCurrencyRate')}</label>
                                    <InputText id="deptCurrencyRate" value={dept.deptCurrencyRate} onChange={(e) => onInputChange(e, 'deptCurrencyRate')} required className={classNames({ 'p-invalid': !isValidatedDeptCurrencyRate })} />
                                    {submitted && !dept.deptCurrencyRate && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="incomingAmount">{t('IncomingAmount')}</label>
                                    <InputText id="incomingAmount" value={dept.incomingAmount} onChange={(e) => onInputChange(e, 'incomingAmount')} onKeyDown={(e) => formatInput(e)} required className={classNames({ 'p-invalid': !isValidatedIncomingAmount })} />
                                    {submitted && !dept.incomingAmount && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-2">
                                    <label htmlFor="currencyDate">{t('AccountCurrencyDate')}</label>
                                    <Calendar id="currencyDate" locale="tr" dateFormat="dd.mm.yy" value={dept.currencyDate} onChange={(e) => setCurrencyDate(e.value)} className={classNames({ 'p-invalid': !isValidateCurrencyDate })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="currencyTypesId">{t('AccountCurrency')}</label>
                                    <Dropdown id="currencyTypesId" value={selectCurrencyTypesValue} options={currencyTypes} onChange={(e) => onCurrencyChange(e, 'currencyTypesId')} optionLabel="name" placeholder="Giren Döviz" disabled={isInputDisabled} className={classNames({ 'p-invalid': !isValidateSelectCurrencyTypesValue })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="currencyRate">{t('AccountCurrencyRate')}</label>
                                    <InputText id="currencyRate" value={dept.currencyRate} onChange={(e) => onInputChange(e, 'currencyRate')} required className={classNames({ 'p-invalid': !isValidatedCurrencyRate })} />
                                    {submitted && !dept.currencyRate && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="paymentTypeId">{t('PaymentType')}</label>
                                    <Dropdown id="paymentTypeId" value={selectPaymentTypesValue} options={paymentTypes} onChange={(e) => setSelectPaymentTypesValue(e.value)} optionLabel="name" placeholder="Ödeme Türü" className={classNames({ 'p-invalid': !isValidateSelectPaymentTypesValue })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="Priview">{t('IncomingAmount')}</label>
                                    <Button label="Önizleme" icon="pi pi-check" className="p-button-sm" onClick={onPreview} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="ekle">{t('Add')}</label>
                                    <Button label="Ekle" icon="pi pi-check" className="p-button-sm" onClick={onAddRow} />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="formgrid grid">
                                <div className="field col-10">
                                    <label htmlFor="description">{t('Description')}</label>
                                    <InputText id="description" value={dept.description} onChange={(e) => onInputChange(e, 'description')} required className={classNames({ 'p-invalid': !isValidatedDescription })} />
                                    {submitted && !dept.description && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="movementAmount">{t('MovementAmount')}</label>
                                    <InputText id="movementAmount" readOnly value={dept.movementAmount} onChange={(e) => onInputChange(e, 'movementAmount')} required className={classNames({ 'p-invalid': !isValidatedMovementAmount })} />
                                    {submitted && !dept.movementAmount && <small className="p-invalid">{t('Required')}</small>}
                                </div>

                            </div>
                            <br></br>
                            <hr></hr>
                            <DataTable value={paymentTables} dataKey="id" responsiveLayout="scroll">
                                <Column field="movementTypeName" header={t('Giriş Tipi')} style={{ width: '20%' }}></Column>
                                <Column field="subAccountName" header={t('Hesap Adı')} style={{ width: '20%' }}></Column>
                                <Column field="deptCurrencyType" header={t('Gelen Döviz')} style={{ width: '20%' }}></Column>
                                <Column field="deptCurrencyRate" header={t('Gelen Kur')} style={{ width: '20%' }}></Column>
                                <Column field="incomingAmount" header={t('Gelen Tutar')} style={{ width: '20%' }}></Column>
                                <Column field="currencyType" header={t('Hesap Döviz')} style={{ width: '20%' }}></Column>
                                <Column field="currencyRate" header={t('Hesap Kur')} style={{ width: '20%' }}></Column>
                                <Column field="movementAmount" header={t('Ödeme')} style={{ width: '20%' }}></Column>
                                <Column rowEditor bodyStyle={{ textAlign: 'center' }} style={{ width: '10%' }}></Column>
                                <Column header="#" body={actionBodyDeptPaymentTemplate} style={{ width: '10%' }}></Column>
                            </DataTable>
                            <hr></hr>
                        </Dialog>
                        <Dialog visible={deptDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deleteDeptDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {dept && <span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={sendLogsDialog} style={{ width: '550px' }} header={t('Confirm')} modal footer={sendLogsDialogFooter} onHide={hideSendLogsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {dept && <span>Seçilen poliçeler havuz'a geri aktarılacak onaylıyor musunuz ?</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={paymentRowDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deletePaymentRowDialogFooter} onHide={hidePaymentRowDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {<span>{t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={sendLogsDialogElementer} style={{ width: '550px' }} header={t('Confirm')} modal footer={sendLogsDialogFooterElementer} onHide={hideSendLogsDialogElementer}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {<span>Seçilen poliçelerin elementerler durumları güncellenecek onaylıyor musunuz ?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Depts, comparisonFn);

